import { UserGroupConstant, userGroupRequiredFieldList } from "presentation/constant/UserGroup/UserGroupConstant";
import { useUserVM } from "presentation/hook/User/useUserVM";
import { useUserTracked } from "presentation/store/User/UserProvider";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo } from "react";
import { FieldType } from "veronica-ui-component/dist/component/core";

const UserGroupForm: React.FC = () => {
    const [userState] = useUserTracked();
    const userVM = useUserVM();
    const { isAdd, isSaveClicked, currentSelectedData, allFormState } = userState.userGroupState;
    const USERGROUP_CONSTANT = UserGroupConstant.userGroup;

    return (
        <div className={'flex-row'}>
            <div className={'flex-row-item'} style={{ width: '230px' }}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={!isAdd}
                    fieldValue={currentSelectedData.nameList}
                    readOnlyValue={currentSelectedData.nameList?.join(",")}
                    fieldLabel={USERGROUP_CONSTANT.USERGROUPNAME}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'nameList'}
                    isMultipleDropDown={true}
                    requiredFieldList={userGroupRequiredFieldList}
                    options={userState.dynamicOptions.userGroupDropdownOptions ?? []}
                    onFieldChange={userVM.onGroupFieldChange} />
            </div>
        </div>
    );
}

export default memo(UserGroupForm);