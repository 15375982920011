import { UserGroupEntity } from "domain/entity/userGroup/UserGroupEntity";
import { INITIAL_USER_GROUP_COL_DEF } from "presentation/constant/UserGroup/UserGroupColumnDefinition";
import { UserGroupConstant } from "presentation/constant/UserGroup/UserGroupConstant";
import { useUserVM } from "presentation/hook/User/useUserVM";
import { useUserTracked } from "presentation/store/User/UserProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useState } from "react";

const UserGroupTablePanel: React.FC = () => {
    const [userState] = useUserTracked();
    const { isEditable, isRead } = userState.userState;
    const { isEditable: isGroupEditable, isAdd: isGroupAdd, isTabularDataActive: isGroupTabularDataActive } = userState.userGroupState;
    const userVM = useUserVM();
    const currentUser = userState.userState.currentSelectedData;
    const [tableRowSelect, setTableRowSelect] = useState<any>({});
    const tableId = 'user-group-table';
    const USERGROUP_CONSTANT = UserGroupConstant.userGroup;

    const handleAddClick = useCallback(() => {
        userVM.onGroupAddClick();
    }, [userVM]);

    const handleRowDoubleClick = useCallback((userGroup: UserGroupEntity) => {
        userVM.onGroupRowDoubleClick(userGroup);
    }, [userVM]);

    const handleDelete = useCallback((userGroup: UserGroupEntity) => {
        userVM.onGroupRowDeleteClick(userGroup);
    }, [userVM]);

    const memoGroupTable = useMemo(() => {
        return (
            <NbisTable
                id={tableId}
                columns={INITIAL_USER_GROUP_COL_DEF}
                headerLabel={USERGROUP_CONSTANT.USERGROUP}
                onAddClick={handleAddClick}
                onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
                data={currentUser.groups || []}
                showPaginator={false}
                editable={false}
                onRowClick={(e: any, i: any) => {
                    setTableRowSelect((prevState: any) => ({
                        ...prevState,
                        [tableId]: isGroupAdd || isGroupEditable ? { ...prevState[tableId] } : { [i]: true },
                    }));
                }}
                isRowHighligted={tableRowSelect[tableId] ? true : false}
                isRowChange={!(isGroupAdd || isGroupEditable)}
                showAddIcon={!(isEditable && isRead) && !isGroupAdd && !isGroupEditable}
                showUploadIcon={!(isEditable && isRead) && !isGroupAdd && !isGroupEditable}
                showDeleteButton={!(isEditable && isRead) && !isGroupAdd && !isGroupEditable}
                showActionColumn={true}
                showReloadIcon={false}
                onDelete={handleDelete}
                selectionMode={false}
                isNewColumnSetting={true}
                isScrollHighlighted={true}
                gridHeight="customHeight"
                customHeight="calc(50vh - 72px)" />
        );
    }, [currentUser.groups, handleAddClick, handleDelete, handleRowDoubleClick, isEditable, isRead, isGroupEditable, isGroupAdd, tableRowSelect, USERGROUP_CONSTANT.USERGROUP])

    return <TableWrapper isHide={!isGroupTabularDataActive}>{memoGroupTable}</TableWrapper>
}

export default memo(UserGroupTablePanel);